header {
  background-color: #1a2c38;
  display: flex;
  justify-content: center;

  box-shadow: #0003 0 4px 6px -1px, #0000001f 0 2px 4px -1px;
  position: relative;

  z-index: 5;
  width: 100%;
  max-width: 100vw;

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    padding: 8px 3vw;
  }
  .logo {
    max-width: 120px;
    transition: 0.3s all;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
  }

  .balance-bar {
    display: flex;
    .wallet-button {
      font-size: 0.915rem;
      padding: 1.05rem 1rem;
      background-color: #ffc800;
      font-weight: bold;
      color: black;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      transition: 0.2s background-color;

      &:hover {
        background-color: rgb(168, 143, 52);
      }
    }

    .balance-num {
      font-weight: bold;
      color: white;
      background-color: #0f212e;
      padding: 1.05rem 1.5rem;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
      text-align: center;
      display: flex;
      align-items: center;
      gap: 4px;
      svg {
        width: 15px;
      }
    }
  }

  .auth-actions-group {
    .profile-dropdown-button {
      background-color: transparent;
      border: none;
      position: relative;
      margin-right: 100px;
      .account-dropdown-menu {
        padding: 12px 8px;
        border-radius: 6px;
        position: absolute;
        cursor: pointer;
        background-color: white;
        color: black;
        left: -110%;
        bottom: -65px;

        .arrow {
          position: absolute;
          background-color: white;
          width: 10px;
          height: 10px;
          transform: rotate(45deg);
          top: -5px;
          left: 45%;
        }
        li {
          display: flex;
          align-items: center;
          gap: 8px;
          p {
            color: #2f4553;
            font-weight: bold;

            font-size: 16px;
          }
          svg {
            color: #2f4553;
          }
        }
      }
      svg {
        width: 18px;
        color: white;
      }
    }
    .sign-in-button {
      background-color: transparent;
      color: white;
      border: none;
      font-weight: bold;
      font-size: 0.875rem;
      padding: 0.9375rem 1.25rem;
      cursor: pointer;
    }
    .register-button {
      color: white;
      background-color: #1475e1;
      border: none;
      font-weight: bold;
      border-radius: 6px;
      font-size: 0.875rem;
      padding: 0.9375rem 1.25rem;
      cursor: pointer;
    }
  }
}
