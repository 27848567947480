.game-footer-wrapper {
  min-height: 64px;
  width: 100%;
  max-width: 1200px;
  background-color: #0f212e;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
}
