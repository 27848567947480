.mines-bet-controls {
  background-color: #213743;
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 300px;

  border-top-left-radius: 8px;

  .play-mines-button {
    background-color: #00e701;
    border: none;
    font-family: inherit;
    padding: 16px 0px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 4px;
    transition: background-color 0.2s, opacity 0.1s;
    cursor: pointer;

    .mini-bomb {
      width: 100%;
      height: 100%;
      max-width: 18px;
    }

    .jiggle {
      animation: jiggle 1s linear infinite;
    }
  }

  .random-tile-button {
    color: white;
    font-family: inherit;
    font-weight: 600;
    width: 100%;
    font-size: 14px;
    padding: 10px 16px;
    background-color: #2f4553;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
    transition: background-color 0.2s, opacity 0.1s;
    cursor: pointer;
    &:hover {
      background-color: #8e96ab92;
    }
  }

  .disabled-action {
    user-select: none;
    pointer-events: none;
    opacity: 0.6;
  }
}

@keyframes jiggle {
  0%,
  20% {
    transform: scale(1) rotate(0deg); /* Initial state */
  }
  30%,
  45% {
    transform: scale(1.15) rotate(0deg); /* Expand to 1.2x */
  }
  55%,
  70% {
    transform: scale(1.15) rotate(-20deg); /* Rotate to the left (-20deg) */
  }
  80%,
  95% {
    transform: scale(1.15) rotate(0deg); /* Rotate to the right (20deg) */
  }
  100% {
    transform: scale(1) rotate(0deg); /* Shrink back to original size */
  }
}
