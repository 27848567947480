@use "../../variables" as v;

.column-span-4 {
  grid-column: span 4;
}

.column-span-2 {
  grid-column: span 2;
}

.column-start-2-span-4 {
  grid-column: 2 / span 4;
}

.column-start-2-span-2 {
  grid-column: 2 / span 2;
}
.standard-button {
  position: relative;
  color: white;
  font-weight: bold;
  border: 3px solid v.$roulette_black;
  background-color: transparent;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s background-color;
}

.standard-button:hover {
  background-color: v.$roulette_light_black;
}
.red {
  position: relative;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border: none;
  background-color: v.$roulette_red;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s background-color;
  cursor: pointer;
}

.red:has(~ .all-red-hover:hover),
.all-red-hover:hover {
  background-color: rgb(254, 110, 134);
}

.black:has(~ .all-black-hover:hover),
.all-black-hover:hover {
  background-color: rgb(75, 110, 132);
}

.chip-stack-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green {
  position: relative;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border: none;
  background-color: v.$roulette_green;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s background-color;
  cursor: pointer;
  grid-column: 1;
  grid-row: 1 / span 3;
  transition: 0.3s background-color;
}

.green:hover {
  background-color: rgb(105, 194, 103);
}

.black {
  position: relative;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border: none;
  background-color: v.$roulette_black;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s background-color;
  cursor: pointer;
}

.black:hover {
  background-color: v.$roulette_light_black;
}

.red:hover {
  background-color: v.$roulette_light_red;
}
