.navigation-container {
  max-width: 240px;
  width: 100%;

  display: flex;
  flex-direction: column;
  background-color: #0f212e;
  box-sizing: border-box;
  user-select: none;
  .mini-nav-top {
    padding: 6px 0px;

    position: relative;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2),
      0 4px 6px -2px rgba(0, 0, 0, 0.1);

    .svg-container {
      padding: 18px;
      svg {
        width: 16px;
      }
    }

    .nav-title {
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);
      font-weight: bold;
      color: white;
      background-image: url("../../images/nav-background.avif");
      background-size: cover;
      font-size: 14px;
      width: 100%;
      padding: 12px 0px;
      text-align: center;

      border-radius: 4px;
    }
  }

  .mini-nav-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    gap: 10px;
    .nav-title-green-block {
      width: 44px;
      height: 44px;
      border-radius: 4px;
      background-image: url("../../images/nav-background.avif");
      background-size: cover;
    }
    .nav-content-block {
      // padding-left: 8px;
      border-radius: 6px;
      background-color: #1a2c38;

      ul {
        font-weight: bold;
        color: white;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        li {
          cursor: pointer;
          display: flex;
          gap: 10px;
          align-items: center;
          padding: 15px;
          svg {
            width: 14px;
          }
        }
      }
    }
  }
}
