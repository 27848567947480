@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes moveAroundWheel {
  0% {
    transform: rotate(floor(random() * 71deg) + 40deg);
  }

  100% {
    transform: rotate(1440deg);
  }
}

.spinning {
  animation: spin 15s linear infinite;
}

.roulette-ball {
  transform-origin: center center;
  animation: moveAroundWheel 3.5s ease-out forwards;
  z-index: 1;
}

.roulette-visible {
  visibility: visible !important;
}
