.blackjack-main {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  background-color: #1a2c38;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;

  .blackjack-game-section {
    display: flex;
    overflow: none;
    width: 100%;
    max-width: 1200px;
    max-height: 700px;
    border-radius: 8px;
    overflow: none;
    .bet-controls {
      background-color: #213743;
      padding: 16px 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      width: 300px;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
      .amount-input-group {
        display: flex;
        flex-direction: column;
        gap: 6px;

        label {
          font-weight: bold;
          color: #b1bad3;
          font-size: 14px;
        }
        .input-wrapper {
          display: flex;
          box-shadow: 0rem 0.0625rem 0.1875rem #00000033,
            0rem 0.0625rem 0.125rem #0000001f;

          .bet-buttons {
            display: flex;
          }
          #bet-amount {
            background-color: #0f212e;
            border: 2px solid #2f4553;
            width: 100%;
            color: white;
            font-size: 16px;
            padding: 8px 24px;

            padding-left: 8px;
            font-weight: bold;
            outline: none;
            transition: all 0.2s;
            box-sizing: border-box;
            position: relative;

            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;

            &:focus {
              border-color: #537a92;
            }
          }

          button {
            border: none;
            background-color: #2f4553;
            color: white;
            font-weight: bold;
            padding: 0.6125rem 1rem;
            font-size: 15px;
          }
          .half-bet-button {
            position: relative;
          }

          .double-bet-button {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }

          .half-bet-button::after {
            content: "";
            position: absolute;
            right: -1px;
            background-color: #0f212e;
            width: 2px;
            top: 25%;
            bottom: 25%;
          }
        }
      }
      .blackjack-actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 16px;
        column-gap: 8px;
      }

      .blackjack-play-button {
        background-color: #00e701;
        border: none;
        font-weight: bold;
        padding: 16px 0px;
        font-size: 16px;
        border-radius: 4px;
        cursor: pointer;
        &:disabled {
          background-color: #108f21;
          color: #121f26;
          cursor: not-allowed;
        }

        &:hover {
          background-color: #1fff20;
        }
      }
    }

    .game-screen {
      box-sizing: border-box;
      background-color: #0f212e;
      flex-grow: 1;
      min-height: 630px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background-image: url("../../images/blackjack-banner-background.svg");
      background-size: 40%;
      background-repeat: no-repeat;
      background-position: center;
      padding-top: 64px;
      padding-bottom: 48px;
      display: grid;
      grid-template-rows: repeat(2, max-content);
      align-content: space-between;
      font-size: 0.84em;
      overflow: hidden;
      position: relative;
      .card-group {
        display: flex;
        width: 100%;

        font-size: 1.2em;
        position: relative;
        z-index: 2;
        justify-content: center;

        .card-stack {
          display: flex;
          min-height: 7.9em;
          min-width: 5em;
          justify-content: center;
          position: relative;
        }
        .cards-value {
          color: white;
          font-weight: bold;
          background-color: #2f4553;
          border-radius: 16px;
          font-size: 14px;
          width: 60px;
          height: 21px;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 4px;

          top: -20px;

          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
            0 1px 2px 0 rgba(0, 0, 0, 0.12);
        }

        .selected-value {
          color: black !important;
          background-color: #4391e7 !important;
        }
      }

      .player-side {
        justify-content: space-around;
      }
    }
  }
}

.selected-hand-arrow {
  width: 32px;
}

@media (min-width: 875px) {
  .game-screen {
    min-height: 630px;
  }
}
