.volume-setting-wrapper {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: transparent;
    svg {
      width: 16px;
      color: #00e701;
    }

    .muted {
      color: white;
    }
  }
  #volume-range-input {
    --current: 70%;
    appearance: none;
    background-color: transparent;
    position: relative;

    &::-webkit-slider-thumb {
      appearance: none;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background-color: white;
      margin-top: -5px;
      z-index: 2;
      position: relative;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 5px;

      border-radius: 5px;
      cursor: pointer;
      background-color: #213743;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      height: 5px;
      left: 0;
      max-width: 100%;

      width: var(--current);
      background-color: #00e701;
      border-radius: 5px;
    }
  }
}
