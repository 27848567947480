.roulette-chip {
  background-color: rgb(252, 76, 17);
  box-shadow: rgb(137, 0, 0) 0px 0.125em 0px 0px;
  border: none;
  background-image: url("../../images/coin-outline.svg");
  position: relative;
  border-radius: 50%;
  width: 2.2rem;
  height: 2.2rem;
  box-sizing: border-box;

  .amount {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    font-size: 0.68em;
    font-weight: 800;
    color: #59250d;
    pointer-events: none;
  }
}

.roulette-chip.white-border::before {
  content: "";
  position: absolute;
  top: -4px; // Adjust these values based on the border width
  right: -4px;
  bottom: -4px;
  left: -4px;
  border: 4px solid white; // Set your desired border width and color
  border-radius: 50%; // If the chips are circular
}
