.playing-card {
  width: 5em;
  height: 7.9em;

  background-color: white;
  border-radius: 6px;
  position: relative;
  user-select: none;
  transform-style: preserve-3d;
  perspective: 700px;

  box-shadow: 0 0 0.25em #0710174d;

  transition-property: box-shadow;
  transition-duration: 0.2s;
  .black-text {
    color: #1a2c38;
  }

  .red-text {
    color: #e9113c;
  }

  .front {
    //backface-visibility: hidden;

    pointer-events: none;
    border-radius: 6px;
    font-size: 2.2em;
    font-weight: bold;
    padding-top: 8px;
    transform: rotateY(180deg);
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: fit-content;
      margin-left: 10%;
      gap: 12px;
    }
    svg {
      width: 1em;
      height: 1em;
    }
  }

  .back {
    border-radius: 6px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 92%;
    backface-visibility: hidden;
    background-color: rgb(255, 255, 255);

    pointer-events: none;
    height: 100%;
    background-image: url("../../images/card-back-chimps2.svg");
  }
}

.selected-hand {
  box-shadow: 0 0 0 0.3em #1475e1; // #4391e7;
}

.lost {
  box-shadow: 0 0 0 0.3em #e9113c;
}

.won {
  box-shadow: 0 0 0 0.3em #1fff20;
}

.push {
  box-shadow: 0 0 0 0.3em #ff9d00;
}
