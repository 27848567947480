#mines-grid {
  // Mine cells format
  aspect-ratio: 1 / 1;
  display: grid;
  grid-template-rows: repeat(5, minmax(0px, 110px));
  grid-template-columns: repeat(5, minmax(0px, 110px));
  column-gap: 12px;
  row-gap: 17px;
  padding: 12px;
  padding-bottom: 16px;
}
.cell-wrapper {
  border: none;
  border-radius: 8px;
  background-color: #071824;
  position: relative;
  .cell-cover {
    border: none;
    border-radius: 8px;

    background-color: #2f4553;
    box-shadow: 0px 7px #213743;
    width: 100%;
    height: 100%;
    pointer-events: all;
    cursor: pointer;
    transition-property: transform, background-color;
    transition-duration: 0.2s;
    &:hover {
      background-color: #8e96ab92;
      transform: translateY(-3px);
    }
  }

  .cell-value {
    position: absolute;
    top: 0;
    left: 0;
    background-size: 70%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(0);
    &.mine {
      background-image: url("../../../images/mine.svg");
    }

    &.gem {
      background-image: url("../../../images/gem.svg");
    }
  }

  .mine-effect {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(1.5);
  }
}

// TODO: make this infinite while we fetch the cell result
.expand-cover {
  animation: expand-cover 0.35s forwards;
}

.shrink-cover {
  animation: shrink 0.15s forwards;
}

.shrink-value {
  animation: shrink 0.15s forwards !important;
}

.expand {
  animation: expand-background 0.15s forwards;
}

.expand-dim {
  opacity: 0.5;
  animation: expand-dim 0.15s forwards;
}

@keyframes expand-background {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shrink {
  from {
    transform: inherit;
  }
  to {
    transform: scale(0);
  }
}

@keyframes expand-dim {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(0.6);
  }
}

// TODO: make this infinite while we fetch the cell result
@keyframes expand-cover {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

@media (max-width: 1050px) {
  #mines-grid {
    column-gap: 6px;
    row-gap: 12px;
    padding: 8px;

    .cell-cover {
      box-shadow: 0px 4px #213743;
    }
  }
}
