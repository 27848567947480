.net-gain-output-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  position: relative;
  font-family: "Segoe UI", sans-serif;

  label {
    font-weight: bold;
    color: #b1bad3;
    font-size: 14px;
    font-family: inherit;
  }

  #net-gain-output-input {
    background-color: #2f4553;
    position: relative;
    width: 100%;
    color: white;
    font-size: 15px;
    padding: 8px 8px;
    font-family: "Segoe UI", sans-serif;
    padding-right: 28px;
    font-weight: bold;
    outline: none;
    transition: all 0.2s;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border: transparent 2px solid;
    &:focus,
    &:hover {
      border: #537a92 2px solid;
    }
  }

  .coin-input-img {
    width: 16px;
    position: absolute;
    right: 12px;
    top: 50%;
    pointer-events: none;
  }
}
