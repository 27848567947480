.blackjack-main {
  .blackjack-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 0.5rem;
    row-gap: 0.3rem;

    button {
      background-color: #2f4553;
      border: none;
      color: white;
      font-weight: bold;
      border-radius: 4px;
      padding: 14px 0px;
      box-shadow: 0rem 0.0625rem 0.1875rem #00000033,
        0rem 0.0625rem 0.125rem #0000001f;
      transition-property: background-color, transform, color;

      transition-duration: 0.2s;
      &:hover {
        cursor: pointer;
        background-color: #557086;
      }

      &:active {
        transform: scale(0.99);
      }

      &:disabled {
        cursor: not-allowed;
        background-color: #283e4b;
        color: #909ba1;
      }
    }
  }

  .blackjack-insurance-offer {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    column-gap: 0.5rem;
    row-gap: 0.3rem;
    h2 {
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      grid-column: 1 / -1;
    }

    button {
      background-color: #2f4553;
      border: none;
      color: white;
      font-weight: bold;
      border-radius: 4px;
      padding: 16px 16px;
      font-size: 14px;
      box-shadow: 0rem 0.0625rem 0.1875rem #00000033,
        0rem 0.0625rem 0.125rem #0000001f;
      transition-property: background-color, transform, color;

      transition-duration: 0.2s;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
        background-color: #557086;
      }

      &:active {
        transform: scale(0.99);
      }

      &:disabled {
        cursor: not-allowed;
        background-color: #283e4b;
        color: #909ba1;
      }
    }
  }
}
